<template>
  <div>
    <div
      class="d-flex flex-wrap align-items-center pb-2 border-bottom mb-4 mt-4"
      style="min-height: 50px"
    >
      <h3>List Angket</h3>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div
          class="col-sm-6 col-lg-3 mb-4"
          v-for="(form, id) in angket"
          :key="id"
        >
          <router-link
            class="text-decoration-none text-reset"
            :to="{
              name: 'Form Angket',
              params: {
                id_form: form.id,
              },
            }"
          >
            <div class="card">
              <div class="card-body">
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                >
                  <fa-icon :icon="['fas', 'file-alt']" size="3x" />
                  <h6 class="text-bold-500 mt-3 card-text">{{ form.nama }}</h6>
                </div>
              </div>
              <div
                class="card-footer text-center text-white"
                :class="form.submitted ? 'bg-success' : 'bg-danger'"
              >
                <div class="card-text">
                  <span
                    class="mr-3"
                    v-text="form.submitted ? 'Sudah Diisi' : 'Belum Diisi'"
                  ></span>
                  <fa-icon
                    :icon="form.submitted ? ['fas', 'check'] : ['fas', 'times']"
                  />
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      angket: [],
    };
  },

  methods: {
    getAngkets() {
      if (!this.$psb.TokenParser.user().uuid) return;
      this.$store.commit("setLoading", true);
      this.$axios
        .get(`/pendaftar/${this.$psb.TokenParser.user().uuid}/form-angket`)
        .then((res) => {
          for (let i in res.data) {
            if (res.data[i].enable) {
              this.angket.push(res.data[i]);
            }
          }

          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          this.$store.commit("setLoading", false);
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
  },

  mounted() {
    this.getAngkets();
  },
};
</script>
