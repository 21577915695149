var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},_vm._l((_vm.angket),function(form,id){return _c('div',{key:id,staticClass:"col-sm-6 col-lg-3 mb-4"},[_c('router-link',{staticClass:"text-decoration-none text-reset",attrs:{"to":{
            name: 'Form Angket',
            params: {
              id_form: form.id,
            },
          }}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('fa-icon',{attrs:{"icon":['fas', 'file-alt'],"size":"3x"}}),_c('h6',{staticClass:"text-bold-500 mt-3 card-text"},[_vm._v(_vm._s(form.nama))])],1)]),_c('div',{staticClass:"card-footer text-center text-white",class:form.submitted ? 'bg-success' : 'bg-danger'},[_c('div',{staticClass:"card-text"},[_c('span',{staticClass:"mr-3",domProps:{"textContent":_vm._s(form.submitted ? 'Sudah Diisi' : 'Belum Diisi')}}),_c('fa-icon',{attrs:{"icon":form.submitted ? ['fas', 'check'] : ['fas', 'times']}})],1)])])])],1)}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap align-items-center pb-2 border-bottom mb-4 mt-4",staticStyle:{"min-height":"50px"}},[_c('h3',[_vm._v("List Angket")])])
}]

export { render, staticRenderFns }